<template>
  <div class="drawer-btns-group">
    <v-list shaped>
      <v-list-item-group v-model="item" color="blue">
        <div v-for="(item, i) in items" :key="i">
          <v-list-item v-if="item.to !== undefined" :to="item.to">
            <v-list-item-icon>
              <v-icon>mdi-{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <a
            class="drawer-btns-link"
            v-if="item.link !== undefined"
            :href="item.link"
            target="_blank"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </a>
        </div>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {
  props: {
    items: [Array, String],
  },

  data: () => ({
    item: null,
  }),
};
</script>

<style scoped>
.drawer-btns-link {
  text-decoration: none;
}
</style>
