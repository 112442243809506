<template>
  <header class="CuckooTools-Header">
    <v-app-bar hide-on-scroll fixed color="transparent">
      <v-app-bar-nav-icon @click.stop="openDrawer"></v-app-bar-nav-icon>

      <v-toolbar-title class="appbar-title">
        <router-link to="/">Cuckoo Tools</router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div class="appbar-buttons">
        <v-btn @click.stop="changeDarkMode" fab small text depressed class="btn-item">
          <v-icon>mdi-{{ darkIcon }}</v-icon>
        </v-btn>
      </div>
    </v-app-bar>

    <NavDrawer></NavDrawer>
  </header>
</template>

<script>
import NavDrawer from "@/components/this/header/drawer";
import { $cookie } from "@/components/libs/cookie";

export default {
  components: {
    NavDrawer,
  },

  data: () => ({
    dark: false,
    darkIcon: "brightness-2",
  }),

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.dark = $cookie.get("dark_mode") == "true" ? true : false;
      this.darkIcon = this.dark === true ? "brightness-5" : "brightness-2";


      if (!$cookie.has("dark_mode")) {
        var _this = this;
        if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
          this.dark = true;
          this.darkIcon = "brightness-5";
        }

        let listeners = {
          dark: function (mediaQueryList) {
            if (mediaQueryList.matches) {
              _this.dark = true;
              _this.darkIcon = "brightness-5";
            }
          },
          light: function (mediaQueryList) {
            if (mediaQueryList.matches) {
              _this.dark = false;
              _this.darkIcon = "brightness-2";
            }
          },
        };

        window.matchMedia("(prefers-color-scheme: dark)").addListener(listeners.dark);
        window.matchMedia("(prefers-color-scheme: light)").addListener(listeners.light);
      }
    },

    openDrawer(data) {
      this.$store.commit("Global/setNavDrawer", data);
    },

    changeDarkMode() {
      this.dark = !this.dark;
      this.$vuetify.theme.dark = this.dark;
      this.darkIcon = this.dark === true ? "brightness-5" : "brightness-2";
      $cookie.set("dark_mode", this.dark);
    },
  },
};
</script>

<style>
.appbar-buttons {
  padding-right: 5px;
}

.appbar-buttons .btn-item {
  margin-right: 5px;
}

.appbar-title a {
  color: #000 !important;
  text-decoration: none;
}

.theme--dark .appbar-title a {
  color: #fff !important;
}
</style>
