module.exports = [
    {
        path: "/",
        meta: {
            title: ""
        },
        component: () => import("@/views/home")
    },
    {
        path: "/tools/owo",
        meta: {
            title: "旧表情配置转换工具"
        },
        component: () => import("@/views/tools/owo")
    },
    {
        path: "/tools/menu",
        meta: {
            title: "侧边抽屉配置生成工具"
        },
        component: () => import("@/views/tools/menu")
    },
    {
        path: "/tools/contact",
        meta: {
            title: "联系方式配置生成工具"
        },
        component: () => import("@/views/tools/contact")
    },
    {
        path: "*",
        meta: {
            title: "页面走丢了"
        },
        component: () => import("@/views/notfound")
    }
]