import Vue from 'vue'
import Vuex from 'vuex'
import Global from './global'
import Config from './config'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Global,
    Config
  }
})