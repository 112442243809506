import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import smoothscroll from 'smoothscroll-polyfill';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#CuckooTools')

smoothscroll.polyfill();

if (process.env.NODE_ENV === 'production') {
  console.clear();
  console.log(
    '\n %c \u2728 Cuckoo Tools %c https://github.com/bhaoo/cuckoo \n',
    'color: #ffffff; background: rgba(30, 136, 229, 1); padding:5px 0;',
    'background:rgba(66, 66, 66, 0.85); padding:5px 0;'
  )
}
