<template>
  <main class="CuckooTools-Main">
    <v-fade-transition mode="out-in">
      <router-view></router-view>
    </v-fade-transition>
  </main>
</template>

<script>
export default {};
</script>

<style>
.CuckooTools-Main {
  flex: 1;
  width: 1024px;
  margin: 0 auto;
  position: relative;
  padding-top: 64px;
}

@media (max-width: 1050px) {
  .CuckooTools-Main {
    width: 95%;
  }
}

@media (max-width: 960px) {
  .CuckooTools-Main {
    padding-top: 56px !important;
  }
}
</style>
