import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from "./routes.js"
import NProgress from 'nprogress';
import store from '@/store';

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  mode: (process.env.NODE_ENV === 'production') ? 'history' : ''
})

router.beforeEach((to, from, next) => {
  if (to.meta.title != undefined) {
    store.commit("Global/changeSiteTitle", to.meta.title);
  }

  NProgress.start();
  next();
})

router.afterEach(transition => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

  setTimeout(() => {
    NProgress.done();
  }, 300);
})

export default router
