<template>
  <v-navigation-drawer temporary fixed left v-model="drawer">
    <div class="drawer-title">Cuckoo Tools</div>

    <DrawerItems :items="navItems"></DrawerItems>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import DrawerItems from "./drawer/items";

export default {
  data: () => ({
    drawer: null,
    navItems: [
        { icon: "home", text: "首页", to: "/" },
        { icon: "link-variant", text: "作者博客", link: "https://dwd.moe/" },
        { icon: "book", text: "主题文档", link: "https://cuckoo.owo.show/#/V2/setup" },
        { icon: "emoticon-cool-outline", text: "旧版表情转换", to: "/tools/owo" },
        { icon: "backburger", text: "抽屉菜单生成", to: "/tools/menu" },
        { icon: "account-supervisor-circle-outline", text: "联系方式生成", to: "/tools/contact" },
    ],
  }),

  computed: {
    ...mapGetters("Global", ["navDrawer", "MeInfo"]),
  },

  watch: {
    navDrawer(data) {
      this.drawer = this.navDrawer;
    },
  },

  components: {
    DrawerItems,
  },
};
</script>

<style scoped>
.drawer-title {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "kule-english";
  font-size: 30px;
}
</style>
