<template>
  <footer class="CuckooTools-Footer">
    &copy; 2021 Cuckoo Tools All rights reserved.
  </footer>
</template>

<script>
export default {};
</script>

<style>
.CuckooTools-Footer {
  z-index: 10;
  flex: 0;
  display: flex;
  padding: 15px;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  font-family: "kule-english"
}
</style>
