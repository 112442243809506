const state = {
    siteTitle: "",
    navDrawer: "",
}

const getters = {
    siteTitle: () => state.siteTitle,
    navDrawer: () => state.navDrawer,
}

const mutations = {
    changeSiteTitle(state, title) {
        state.siteTitle = title
    },

    setNavDrawer(state, drawer) {
        state.navDrawer = drawer
    },
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}