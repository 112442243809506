<template>
  <v-app id="CuckooTools">
    <div class="CuckooTools-BG" :style="{ backgroundImage: 'url(' + yousa + ')' }"></div>
    <viewHeader></viewHeader>
    <viewMain></viewMain>
    <viewFooter></viewFooter>
  </v-app>
</template>

<script>
import "nprogress/nprogress.css";
import { mapGetters } from "vuex";
import { $cookie } from "@/components/libs/cookie";
import viewHeader from "@/views/viewHeader";
import viewMain from "@/views/viewMain";
import viewFooter from "@/views/viewFooter";

export default {
  name: "App",

  data: () => ({
    yousa: require("@/assets/images/yousa-bg.jpg"),
  }),

  components: {
    viewHeader,
    viewMain,
    viewFooter,
  },

  watch: {
    siteTitle: {
      handler() {
        if (this.siteTitle == "") {
          document.title = this.thisSite.site.name;
        } else {
          document.title = this.siteTitle + " - " + this.thisSite.site.name;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    ...mapGetters("Global", ["siteTitle"]),
    ...mapGetters("Config", ["thisSite"]),
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      if ($cookie.has("dark_mode")) {
        this.$vuetify.theme.dark = $cookie.get("dark_mode") == "true" ? true : false;
        setTimeout(() => {
          document.querySelector(".CuckooTools-BG").classList.add("ta");
        }, 100);
      } else {
        var _this = this;
        if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
          this.$vuetify.theme.dark = true;
        }
        let listeners = {
          dark: function(mediaQueryList) {
            if (mediaQueryList.matches) {
              _this.$vuetify.theme.dark = true;
            }
          },
          light: function(mediaQueryList) {
            if (mediaQueryList.matches) {
              _this.$vuetify.theme.dark = false;
            }
          },
        };

        window.matchMedia("(prefers-color-scheme: dark)").addListener(listeners.dark);
        window.matchMedia("(prefers-color-scheme: light)").addListener(listeners.light);
      }
    },
  },
};
</script>

<style>
@import "./assets/fonts/kule-english/font.css";

* {
  -webkit-tap-highlight-color: transparent;
}

html {
  overflow-y: auto !important;
}

.CuckooTools-BG {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  position: fixed;
  transition: all 0.3s;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 50% 50%;
}

.CuckooTools-BG.ta::before {
  transition: all 0.3s;
}

.CuckooTools-BG::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  content: "";
  position: fixed;
  background-color: rgba(255, 255, 255, 0.95);
}

.theme--dark .CuckooTools-BG::before {
  background-color: rgba(66, 66, 66, 0.95);
}

#nprogress .bar {
  height: 3px;
}
</style>
